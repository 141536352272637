import { useEffect, useState } from "react";
import classes from "./MobileNavigation.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tabActions } from "../../../store/tabs";

const MobileNavigation = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const resetTabsHandler = () => {
    dispatch(tabActions.resetActiveTabs());
  };

  useEffect(() => {
    if (navIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = null;
    }

    return () => {
      document.body.style.overflow = null;
    };
  }, [navIsOpen]);

  const navSwitch = () => {
    setNavIsOpen((prevState) => !prevState);
  };

  return (
    <div
      className={`${classes["mobile-nav"]} ${
        navIsOpen ? classes["mobile-nav--open"] : ""
      }`}
    >
      <div className={classes["mobile-nav__button"]} onClick={navSwitch}>
        <span className={classes["mobile-nav__icon"]}></span>
      </div>
      <div className={classes["mobile-nav__background"]}></div>
      <nav className={classes["mobile-nav__nav"]}>
        <ul className={classes["mobile-nav__links"]} onClick={navSwitch}>
          <li>
            <NavLink to="/" onClick={resetTabsHandler}>
              Home
            </NavLink>
          </li>
          {isAuth && (
            <>
              <li>
                <NavLink to="profile" onClick={resetTabsHandler}>
                  Profile
                </NavLink>
              </li>
            </>
          )}
          <li>
            <NavLink to="about" onClick={resetTabsHandler}>
              About
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileNavigation;
